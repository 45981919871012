<template>
  <div class="citationTrackingPage" v-loading="loading">
    <p class="title">
      本刊共有<span>{{ info.countReadArticle }}</span
      >篇推送文章被引用，累计被引<span>{{ info.countPublishArticle }}</span
      >次
    </p>
    <div class="article-reading mb-20 mt-20">
      <div class="first-title mb-20">推送文章被引详情列表</div>
      <commonTable
        :table-data="articleTableData"
        :column-data="articleColumn"
        :init-obj="articlePageObj"
        show-handle
        handle-text="操作"
        handle-content="详情"
        @onChangePageData="
          (pageObj) => {
            getTableData(pageObj)
          }
        "
        @onHandleClick="openArticleDetails"
      />
    </div>
  </div>
</template>

<script>
import { Get } from 'kjqk-commons/src/common/index.js'
import { mapState } from 'vuex'
import commonTable from '@comp/commonTable'
export default {
  name: 'CitationTrackingPage',
  components: { commonTable },
  props: {},
  data() {
    return {
      info: {
        countPublishArticle: 0,
        countReadArticle: 0
      },
      articleTableData: [],
      articleColumn: [
        {
          title: '推送文章标题',
          prop: 'title',
          special: true,
          notSortable: true,
          clickParam: 'url'
        },
        {
          title: '年份',
          prop: 'year',
          notSortable: true
        },
        {
          title: '刊期',
          prop: 'magNumber',
          notSortable: true
        },
        {
          title: '推送邮件标题',
          prop: 'topic',
          notSortable: true
        },
        {
          title: '被引次数',
          prop: 'citingTimes',
          notSortable: true
        }
      ],
      articlePageObj: {
        page: 1,
        size: 10
      },
      loading: false
    }
  },
  watch: {},
  computed: {
    ...mapState('incrementService', ['magId'])
  },
  mounted() {
    this.loading = true
    this.getTableData()
    this.getInfo()
  },
  methods: {
    getInfo() {
      let url = '/das-api/vas/citAfterRead/statisticByMagId'
      let params = {
        magId: this.magId
      }
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.info = data
        }
      })
    },
    getTableData(pageParams = {}) {
      let url = '/das-api/vas/citAfterRead/articleInfo'
      const { page = 1, size = 10 } = pageParams
      const params = {
        page: page - 1,
        size,
        magId: this.magId
      }
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.loading = false
          this.articleTableData = content
          this.articlePageObj = {
            page,
            size,
            total: totalElements
          }
        }
      })
    },
    openArticleDetails(item) {
      let routeData = this.$router.resolve({
        path: '/citationTrackingDetails',
        query: {
          columnId: this.$route.query.columnId,
          articleId: item.id
        }
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style lang="scss"></style>
<style lang="scss" scoped>
.citationTrackingPage {
  padding: 20px;
  .title {
    font-weight: bold;
    font-size: 16px;
    span {
      color: red;
    }
  }
}
</style>
